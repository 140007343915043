/**
 * Hubspot pages URL's
 * @see {@link https://public.3.basecamp.com/p/XSix19Ag5mUgh1EpnqyyVanv Lista de landing pages}
 * */
export const LANDING_PAGES = {
  UPGRADE_PLAN: 'https://lp.clicksign.com/solicitar-alteracao-de-plano',
  UPGRADE_API_INTEGRATIONS: 'https://lp.clicksign.com/app-upgrade-plano-avancado',
  ACCOUNT_CANCELLATION: 'https://lp.clicksign.com/solicitar-cancelamento-clicksign',
  ADVANCED_PLAN: 'https://lp.clicksign.com/app-plano-avancado',
};

/**
 * Hubspot Forms
 * TODO: Add link to forms
 * */
export const FORMS = {
  PAYMENT_VOUCHER: 'https://share.hsforms.com/1-2Wips6VRbyBz31CLtKbAwe56rl',
  MEXICO_FEEDBACK_SURVEY: 'https://survey.hsforms.com/1ZAQmLW0oTCWzXXOD4Az30ge56rl',
};
